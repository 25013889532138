.list-card {
    width: 300px; 
    height: 250px; 
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, box-shadow 0.2s; 
}

.list-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.list-card > h2{
    margin-top: 0;
}

.item-content {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem; 
}

.data ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.item-title {
    border: none;
    padding: 0;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    font-size: 1rem;
    line-height: 1.5;
    resize: none;
    cursor: default;
    flex-grow: 1;
    margin: 0;
}