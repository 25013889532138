/* Dashboard.css */

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .dashboard-header h1 {
    margin: 0;
  }
  
  .dashboard-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .list-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .list-cards-container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .list-cards-container button {
    margin-top: 10px;
  }
  
  .add-new-list-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-new-list-card:hover {
  background-color: #f0f0f0;
}

.add-new-list-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    width: 340px;
    height: 60px;
    margin-top: 1.6rem;
  }
  
  .add-new-list-button:hover {
    background-color: #0056b3; /* Darker color on hover */
  }