/* Form.css */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.form-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

.form-button {
  width: 100%;
  padding: 0.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-error {
  color: red;
  margin-top: 0.5rem;
}

.form-link {
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #007bff;
  text-decoration: none;
}

.form-link:hover {
  text-decoration: underline;
}