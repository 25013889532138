
/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Darken the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure the overlay is above other content */
}

.modal-card {
  background-color: white;
  max-width: 400px; /* Set the maximum width of the card */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; 
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #333;
    font-size: 16px;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    margin-top: 1rem;
  }
  
  .modal-save {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .modal-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 18px;
  }

  .delete-button:hover {
    cursor: pointer;
  }

  .modal-title-editable {
    font-size: 1.5rem; 
    font-weight: bold; 
    border: none;
    width: 100%; 
    padding: 0.5rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
  }

  .draggable-item {
    margin-bottom: 0.5rem;
  }
  
  .item-content {
    display: flex;
    align-items: center;
  }
  
  .drag-handle {
    cursor: grab;
    margin-right: 8px; 
  }
  .drag-handle:hover {
    cursor: grab;
  }
  
  .item-details {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust the spacing between items */
  }

  .checkbox {
    margin-right: 8px; /* Add margin between checkbox and item title */
  }

  .checkbox:hover {
    cursor: pointer;
  }
  
  .item-title {
    border: none;
    padding: 0;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    font-size: 1rem;
    line-height: 1.5;
    resize: none;
    cursor: default;
  }

  .item-title:hover {
    cursor: text;
  }


  .modal-delete {
    margin-right: 0.5rem; /* Add margin to the left */
  }
  
  .modal-button {
    /* Add your existing styles here */
    width: 30px; /* Square size */
    height: 30px; /* Square size */
    margin-right: 10px; /* Spacing between arrow buttons and other buttons */
  }