.sign-out-button {
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 16px;
  }
  
  .sign-out-button:hover {
    color: red;
    cursor: pointer;
  }